.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: $breadcrumb-padding-y $breadcrumb-padding-x;
  margin-bottom: $breadcrumb-margin-bottom;
  list-style: none;
  background-color: $breadcrumb-bg;
  @include border-radius($breadcrumb-border-radius);
}

.breadcrumb-item {
  + .breadcrumb-item {
    padding-left: $breadcrumb-item-padding;

    &::before {
      display: inline-block;
      padding-right: $breadcrumb-item-padding;
      color: $breadcrumb-divider-color;
      content: $breadcrumb-divider;
    }
  }

  + .breadcrumb-item:hover::before {
    text-decoration: underline;
  }
  + .breadcrumb-item:hover::before {
    text-decoration: none;
  }

  &.active {
    color: $breadcrumb-active-color;
  }
}
