.navbar {

	&:before {
		content: '';
		position: absolute;
		top: 0; bottom: 0; left: 0; right: 0;
		background-color: white;
		border-bottom: 1px solid $border-color;
		opacity: 0;
		transition: $transition-fade;
		z-index: -1;
	}

	&-dark {
		color: $navbar-dark-color;

		> .container {
			position: relative;

			&:after {
				content: '';
				position: absolute;
				bottom: -$navbar-padding-y; left: 0; right: 0;
				border-top: 1px solid $border-color-light;
			}
		}
	}

	&-light {
		color: $navbar-light-color;

		&:before {
			opacity: 1;
		}
	}

	.nav {

		&-link {
			position: relative;
			font-size: $navbar-nav-link-font-size;
			font-weight: $font-weight-bold;
			text-transform: uppercase;
			transition: $transition-base;
			transition-property: color;

			@include media-breakpoint-up(xl) {

				&:before {
					display: none;
					content: '';
					position: absolute;
					left: $navbar-nav-link-padding-x;
					right: $navbar-nav-link-padding-x;
					bottom: -$navbar-padding-y - .5rem;
					border-top: 2px solid $primary;
				}
			}

			&.active {

				@include media-breakpoint-up(xl) {

					&:before {
						display: block;
					}
				}
			}
		}

		&-item {

			&.active {

				> .nav-link {

					@include media-breakpoint-up(xl) {

						&:before {
							display: block;
						}
					}
				}
			}
		}
	}

	.dropdown {

		&-menu {

			@include media-breakpoint-down(lg) {
				padding-top: 0;
				padding-bottom: 0;
				border: 0;
			}
		}
	}
}
