.breadcrumb {
	margin-top: $navbar-height;

	&-heading {
		margin-bottom: 0;
		font-family: $font-family-base;
		font-weight: 300;
		color: $breadcrumb-heading-color;
	}

	&-item {
		font-size: $breadcrumb-font-size;

		&:before {
			font: {
				family: 'Font Awesome 5 Free';
				weight: 900;
			}
		}
	}
}
