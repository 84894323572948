.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: $spacer;
  background-color: $table-bg;

  th,
  td {
    padding: $table-cell-padding;
    vertical-align: top;
    border-top: $table-border-width solid $table-border-color;
  }

  thead th {
    vertical-align: bottom;
    border-bottom: (2 * $table-border-width) solid $table-border-color;
  }

  tbody + tbody {
    border-top: (2 * $table-border-width) solid $table-border-color;
  }

  .table {
    background-color: $body-bg;
  }
}

.table-sm {
  th,
  td {
    padding: $table-cell-padding-sm;
  }
}

@each $color, $value in $theme-colors {
  @include table-row-variant($color, theme-color-level($color, -9));
}

@include table-row-variant(active, $table-active-bg);

.table {
  .thead-dark {
    th {
      color: $table-dark-color;
      background-color: $table-dark-bg;
      border-color: $table-dark-border-color;
    }
  }

  .thead-light {
    th {
      color: $table-head-color;
      background-color: $table-head-bg;
      border-color: $table-border-color;
    }
  }
}
