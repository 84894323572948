.mat-mdc-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  opacity: 0 !important; /*click effect color change*/
  background-color: #203a7f !important;
}

.mat-mdc-radio-button.mat-accent .mdc-radio__inner-circle {
  border-color: #203a7f !important; /*inner circle color change*/
}

.mat-mdc-radio-button.mat-accent.mat-mdc-radio-checked .mdc-radio__outer-circle {
  border-color: #203a7f !important; /*outer ring color change*/
}

.mdc-radio__outer-circle {
  border-color: #203a7f !important; /*outer ring color change*/
}

.mat-mdc-radio-button ~ .mat-mdc-radio-button {
  margin-left: 16px;
}

.mdc-radio__background {
  &::before {
    display: none;
  }
}

.mdc-form-field>label {
  margin-bottom: 0 !important;
}
