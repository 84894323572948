.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal;
  display: none;
  overflow: hidden;
  outline: 0;

  .modal-open & {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.modal-header {
  background: white;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: $modal-header-padding;
  border-bottom: $modal-header-border-width solid $modal-header-border-color;
  @include border-top-radius($modal-content-border-radius);

  .close {
    padding: $modal-header-padding;
    margin: (-$modal-header-padding) (-$modal-header-padding) (-$modal-header-padding) auto;
  }
}

.modal-body {
  background: white;
  position: relative;
  flex: 1 1 auto;
  padding: $modal-inner-padding;
}

.modal-footer {
  background: white;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: $modal-inner-padding;
  border-top: $modal-footer-border-width solid $modal-footer-border-color;

  > :not(:first-child) { margin-left: .25rem; }
  > :not(:last-child) { margin-right: .25rem; }
}

@include media-breakpoint-up(sm) {
  .modal-dialog {
    max-width: $modal-md;
    margin: $modal-dialog-margin-y-sm-up auto;
  }

  .modal-dialog-centered {
    min-height: calc(100% - (#{$modal-dialog-margin-y-sm-up} * 2));
  }

  .modal-content {
    @include box-shadow($modal-content-box-shadow-sm-up);
  }

  .modal-sm { max-width: $modal-sm; }

}

@include media-breakpoint-up(lg) {
  .modal-lg { max-width: $modal-lg; }
}
