.cookies-background {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #203a7f;
  color: #FFFFFF;
  z-index: 1001;
  padding: 20px 10px;
  text-align: center;
  font-size: 12px;
  line-height: 28px;
}

.cookies-background .btn-primary {
  padding: 8px 15px 4px 15px !important;
  margin: 0 0 0 10px !important;
  text-decoration: none;
  display: inline-block;
  border: 1px #828a91 solid;
}

.cookies-edit {
  padding-bottom: 10px !important;
}

.cookies-edit h5:not(:first-child) {
  margin-top: 24px;
}

.cookies-edit-modal {
  width: 75% !important;
  height: fit-content;
}

.cookies-surface-image {
  width: 30px;
}
