@font-face {
	font-family: 'Objective';
	font-weight: 600;
	src: url('../../fonts/Objective/Objective-Bold.woff2') format('woff2');
}
@font-face {
	font-family: 'Objective';
	font-weight: 500;
	src: url('../../fonts/Objective/Objective-Medium.woff2') format('woff2');
}
@font-face {
	font-family: 'Objective';
	font-weight: 400;
	src: url('../../fonts/Objective/Objective-Regular.woff2') format('woff2');
}

h1, h2, h3, h4, h5, h6 {
	letter-spacing: $headings-letter-spacing;
}

.lead {
	line-height: $lead-line-height;
}

.text {
	&-xs {
		font-size: $font-size-xs;
	}
	&-sm {
		font-size: $font-size-sm;
	}
	&-lg {
		font-size: $font-size-lg;
	}
	&-xl {
		font-size: $font-size-xl;
	}
  &-vertical {
		position: relative;
		transform-origin: right bottom;

		@include media-breakpoint-up(md) {
			transform: rotate(-90deg) translateX(50%) translateX(3rem);
			white-space: nowrap;
		}

		> span {
			position: relative;

			&:before {
				content: '';
				position: absolute;
				top: 50%; right: calc(100% + 1rem);
				border-top: 2px solid;
				width: 2rem;
				transform: translateY(50%);
			}
		}
	}
	&-white {
		&.text-muted {
			color: $text-white-muted !important;
		}
	}
}

::selection {
	background-color: $selection-bg;
	color: $selection-color;
}
