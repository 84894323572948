.form-control {
  display: block;
  width: 100%;
  padding: $input-padding-y $input-padding-x;
  font-size: $font-size-base;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;

  @if $enable-rounded {
    border-radius: $input-border-radius;
  } @else {
    border-radius: 0;
  }

  @include box-shadow($input-box-shadow);
  @include transition($input-transition);

  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  @include form-control-focus();

  &::placeholder {
    color: $input-placeholder-color;
    opacity: 1;
  }

  &:disabled,
  &[readonly] {
    background-color: $input-disabled-bg;
    opacity: 1;
  }
}

select.form-control {
  &:not([size]):not([multiple]) {
    height: $input-height;
  }

  &:focus::-ms-value {
    color: $input-color;
    background-color: $input-bg;
  }
}


.form-control-sm {
  padding: $input-padding-y-sm $input-padding-x-sm;
  font-size: $font-size-sm;
  line-height: $input-line-height-sm;
  @include border-radius($input-border-radius-sm);
}

select.form-control-sm {
  &:not([size]):not([multiple]) {
    height: $input-height-sm;
  }
}

.form-control-lg {
  padding: $input-padding-y-lg $input-padding-x-lg;
  font-size: $font-size-lg;
  line-height: $input-line-height-lg;
  @include border-radius($input-border-radius-lg);
}

select.form-control-lg {
  &:not([size]):not([multiple]) {
    height: $input-height-lg;
  }
}

.form-group {
  margin-bottom: $form-group-margin-bottom;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;

  > .col,
  > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
}

@include form-validation-state("valid", $form-feedback-valid-color);
@include form-validation-state("invalid", $form-feedback-invalid-color);
