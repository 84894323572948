.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: $card-bg;
  background-clip: border-box;
  border: $card-border-width solid $card-border-color;
  @include border-radius($card-border-radius);

  > hr {
    margin-right: 0;
    margin-left: 0;
  }

  > .list-group:first-child {
    .list-group-item:first-child {
      @include border-top-radius($card-border-radius);
    }
  }

  > .list-group:last-child {
    .list-group-item:last-child {
      @include border-bottom-radius($card-border-radius);
    }
  }
}

.card-body {
  flex: 1 1 auto;
  padding: $card-spacer-x;
}

.card-title {
  margin-bottom: $card-spacer-y;
}

.card-subtitle {
  margin-top: -(calc($card-spacer-y / 2));
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-columns {
  .card {
    margin-bottom: $card-columns-margin;
  }

  @include media-breakpoint-up(sm) {
    column-count: $card-columns-count;
    column-gap: $card-columns-gap;
    orphans: 1;
    widows: 1;

    .card {
      display: inline-block;
      width: 100%;
    }
  }
}
