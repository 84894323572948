@mixin table-row-variant($state, $background) {
  .table-#{$state} {
    &,
    > th,
    > td {
      background-color: $background;
    }
  }

  .table-hover {
    $hover-background: darken($background, 5%);

    .table-#{$state} {
      @include hover {
        background-color: $hover-background;

        > td,
        > th {
          background-color: $hover-background;
        }
      }
    }
  }
}
