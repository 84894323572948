// Options
$enable-rounded: false;
$enable-animation: true;

// Z-index
$zindex-preloader: 1080;

// Colors
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: tint(#6c757d, 15%);
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;

// Brand
$primary-light: tint($primary, 20%);

// Fonts
$font-family-sans-serif: 'Objective', sans-serif;
$font-size-base: .9375rem;
$font-size-xl: $font-size-base * 1.25;
$font-size-lg: $font-size-base * 1.1;
$font-size-sm: $font-size-base * .8;
$font-size-xs: $font-size-base * .75;
$font-size-xxs: $font-size-base * .7;
$font-weight-bold: 500;
$headings-font-family: 'Objective', sans-serif;
$headings-line-height: 1.35;
$headings-font-weight: 500;
$headings-color: $gray-900;
$headings-letter-spacing: -.05em;
$h1-font-size: calc(#{$font-size-base} + 2.75vmin);
$h2-font-size: calc(#{$font-size-base} + 1.75vmin);
$h3-font-size: calc(#{$font-size-base} + 1vmin);
$h4-font-size: calc(#{$font-size-base} + 0.5vmin);
$h5-font-size: calc(#{$font-size-base} + 0.25vmin);
$h6-font-size: calc(#{$font-size-base} + 0.00vmin);
$line-height-base: 1.75;
$lead-font-size: $font-size-lg;
$lead-line-height: $line-height-base;
$text-zigzag-border-color: $primary;
$text-muted: $gray-600;
$text-white-muted: fade-out(white, .35);
$small-font-size: 80%;
$selection-bg: $primary;
$selection-color: white;

// Body
$body-color: $gray-900;

// Paragraphs
$paragraph-margin-bottom: 1.5rem;

// Components
$border-color: fade-out(black, .9);
$border-color-light: fade-out(white, .9);
$border-radius: 0;
$box-shadow: 0 25px 100px -5px fade-out(black, .85);
$box-shadow-dark: 0 25px 100px -5px fade-out(black, .5);
$transition-duration-base: .2s;
$transition-duration-xl: $transition-duration-base * 3;
$transition-duration-lg: $transition-duration-base * 2;
$transition-duration-sm: $transition-duration-base * .5;

// Spacing
$spacer: 1rem;
$spacers: (
	-1: ($spacer * -.25),
	-2: ($spacer * -.5),
	-3: ($spacer),
	-4: ($spacer * -1.5),
	-5: ($spacer * -3),
	-6: ($spacer * -4.5),
	-7: ($spacer * -6),
	-8: ($spacer * -7.5),
	-9: ($spacer * -9),
	-10: ($spacer * -9.5),
	6: ($spacer * 4.5),
	7: ($spacer * 6),
	8: ($spacer * 7.5),
	9: ($spacer * 9),
	10: ($spacer * 9.5),
);

// Grid containers
$container-max-widths: (
	xl: 1200px
);

// Grid columns
$grid-gutter-width: 3rem;

// Buttons + Forms
$input-btn-padding-y: 1rem;
$input-btn-padding-x: 1.5rem;
$input-btn-padding-y-sm: .75rem;
$input-btn-padding-x-sm: 1.25rem;
$input-btn-padding-y-lg: 1.25rem;
$input-btn-padding-x-lg: 1.75rem;
$input-btn-focus-box-shadow: none;

// Buttons
$btn-font-size: $font-size-xs;
$btn-box-shadow: none;

// Forms
$input-padding-y: $input-btn-padding-y;
$input-padding-x: .75rem;
$input-line-height: $btn-font-size * $line-height-base;
$input-bg: $gray-100;
$input-border-color: $gray-300;
$input-focus-border-color: $primary;
$input-group-addon-color: $gray-600;
$input-group-addon-bg: $gray-100;

// Navbar
$navbar-padding-y: 1.25rem;
$navbar-height: 5.64rem;
$navbar-nav-link-padding-x: 1rem;
$navbar-nav-link-font-size: $font-size-xs;
$navbar-dark-color: white;
$navbar-dark-hover-color: $primary;
$navbar-dark-active-color: $navbar-dark-hover-color;
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='https://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
$navbar-dark-toggler-border-color: transparent;
$navbar-light-color: $gray-900;
$navbar-light-hover-color: $primary;
$navbar-light-active-color: $navbar-light-hover-color;
$navbar-light-toggler-icon-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='https://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
$navbar-light-toggler-border-color: transparent;

// Sections
$section-padding-y-sm: 5rem;
$section-padding-y: 7.5rem;

// Dropdowns
$dropdown-min-width: 12.5rem;
$dropdown-padding-y: 0;
$dropdown-border-color: fade-out(black, .9);
$dropdown-box-shadow: none;
$dropdown-link-font-size: $font-size-xxs;
$dropdown-link-hover-bg: $gray-100;
$dropdown-link-hover-color: $body-color;
$dropdown-link-active-color: $navbar-light-active-color;
$dropdown-link-active-bg: transparent;
$dropdown-item-padding-y: .75rem;
$dropdown-item-padding-y-sm: .5rem;
$dropdown-item-transition: background-color $transition-duration-sm;

// Modals
$modal-backdrop-opacity: .9;
$modal-header-border-width: 0;
$modal-header-padding: 1rem;
$modal-footer-border-width: 0;

// Breadcrumbs
$breadcrumb-padding-x: 0;
$breadcrumb-padding-y: 1.5rem;
$breadcrumb-margin-bottom: 0;
$breadcrumb-bg: $gray-800;
$breadcrumb-divider-color: fade-out(white, .5);
$breadcrumb-active-color: fade-out(white, .5);
$breadcrumb-divider: "\f105";
$breadcrumb-heading-color: white;
$breadcrumb-font-size: $font-size-base * .85;

// Utilities
$bg-triangle-dark-bg: $gray-900;
$bg-triangle-dark-opacity: .01;
$bg-triangle-light-bg: white;
$bg-triangle-light-opacity: .01;

// Map
$map-height: 25%;
$map-height-sm: 100%;

// Icons
$icon-person-path-length: 328;
$icon-envelope-path-length: 477;
