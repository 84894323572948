.btn {
	position: relative;
	font-size: $btn-font-size;
	font-weight: $font-weight-bold;
	text-transform: uppercase;

	&[class*="outline"] {
		color: $body-color;

		&.dropdown-toggle {
			background-color: transparent;
		}

		&:hover, &:focus, &:active, &:active:focus {
			background-color: transparent;
		}

		&[class*="primary"] {

			&:hover, &:focus, &:active, &:active:focus {
				color: $primary;
			}
		}
	}

	&.btn-link {
		color: $body-color;
	}

	&:focus {
		box-shadow: $btn-focus-box-shadow !important;
	}
}
