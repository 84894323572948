.footer {

	&-links {
		list-style-type: none;
		padding-left: 0;

		@include media-breakpoint-up(md) {
			display: flex;
		}

		> li {

			+ li {
				margin: .5rem 0 0 0;

				@include media-breakpoint-up(md) {
					margin: 0 0 0 1.5rem;
				}
			}
		}
	}
}
