.dropdown {

	&-menu {

		@if ( $enable-animation ) {
			transform: translateY(.5rem);
			opacity: 0;

			&.show {
				animation: $transition-duration-base forwards fade-up;

				@keyframes fade-up {
					to {
						transform: translateY(0);
						opacity: 1;
					}
				}
			}
		}
	}

	&-item {
		padding-top: $dropdown-item-padding-y-sm;
		padding-bottom: $dropdown-item-padding-y-sm;
		font-size: $dropdown-link-font-size;
		font-weight: $font-weight-bold;
		text-transform: uppercase;
		transition: $dropdown-item-transition;

		@include media-breakpoint-up(xl) {
			padding-top: $dropdown-item-padding-y;
			padding-bottom: $dropdown-item-padding-y;
		}

		+ .dropdown-item {

			@include media-breakpoint-up(xl) {
				border-top: 1px solid $dropdown-border-color;
			}
		}
	}
}
