@import "assets/bootstrap/scss/bootstrap";
@import "../node_modules/snazzy-info-window/dist/snazzy-info-window.css";
@import "@angular/material/prebuilt-themes/indigo-pink.css";

.image {
  height: 100%;
  width: 100%;
}

.drop-pointer {
  cursor: pointer;
}

.animation {
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.demo-one {
  height: 20vh;
  background: inherit;
}

.demo-one img {
  height: 20vh;
  width: 27vh;
  border-left: 1px solid #0e2231;
}

.demo-ja::-webkit-scrollbar {
  display: none;
}

/* Small devices (landscape phones) */
@media (min-width: 0px) {
  .logo {
    width: 47%;
  }
  .galery-selectedPhoto {
    left: 0 !important;
  }
}

@media (min-width: 575px) {
  .logo {
    width: 47%;
  }
  .galery-selectedPhoto {
    left: 17% !important;
  }
}

/* Medium devices (tablets) */
@media (min-width: 768px) {
  .logo {
    width: 47%;
  }
  .galery-selectedPhoto {
    left: 21% !important;
  }
}

/* Large devices (desktops) */
@media (min-width: 992px) {
  .logo {
    width: 47%;
  }
  .galery-selectedPhoto {
    left: 30% !important;
  }
}

/* Extra large devices (large desktops) */
@media (min-width: 1200px) {
  .logo {
    width: 47%;
  }
  .galery-selectedPhoto {
    left: 30% !important;
  }
}

/* The given screen size or smaller */
/* Extra small devices (portrait phones)*/
@media (max-width: 575px) {
  .mobileShow {
    display: none;
  }
  .map-info-window {
    width: 70vw !important;
    height: 40vh !important;
  }
  .galery-selectedPhoto {
    left: 0;
  }
  .surface-detail-direction {
    height: auto !important;
  }
  .si-content::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  .si-content::-webkit-scrollbar-thumb {
    background: #203a7f;
  }

  /* Handle on hover */
  .si-content::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

/* Small devices (landscape phones)*/
@media (max-width: 767px) {
  .surface-detail-direction {
    height: auto !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .catalogs-gallery {
    width: 70%;
  }
}

@media (min-width: 575px) and (max-width: 767px) {
  .catalogs-gallery {
    width: 95%;
  }
}

.legend-color {
  width: 22px;
  height: 14px;
  border-radius: 3px;
}

.row-surface {
  height: 20px;
}

.table-btn {
  height: 27px;
  font-size: 9px;
  text-align: center;
  width: max-content;
  padding: 6px;
}

.hidden {
  display: none !important;
}

.si-content {
  overflow: hidden;
  overflow-y: scroll;
  max-height: 390px !important;
}

.checkContainer {
  display: inline-block;
  margin-left: 7px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  bottom: 5px;
}

.checkContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  content: "ss";
  color: red;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 2px;
  height: 37px;
  width: 86px;
  color: #707070;
  content: "Pridat";
  border: 1px solid #707070;
}

.checkContainer:hover input ~ .checkmark {
  background-color: #ccc;
}

.checkContainer input:checked ~ .checkmark {
  background-color: white;
  border-radius: 2px;
}

.checkmark:after {
  content: "Odobrať";
  width: 64px !important;
  background: white;
  position: absolute;
  display: none;
}

.checkContainer input:checked ~ .checkmark:after {
  display: block;
}

.checkContainer .checkmark:after {
  left: 14px;
  top: 9px;
  padding: 2px;
  width: 50px !important;
  height: 15px !important;
  background: white;
  border: solid white;
  border-width: 0 3px 3px 0;
}

.check-span {
  top: 10px;
  left: 21px;
  position: relative;
}

.is-invalid {
  color: red;
  font-size: 10px;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #203a7f;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#home::-webkit-scrollbar {
  width: 6px;
}

#home::-webkit-scrollbar-track {
  background: #f1f1f1;
}

#home2::-webkit-scrollbar-thumb {
  background: #ff2a00 !important;
}

#home::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.map-info-window {
  width: 50vw;
  height: 410px;
}

.logo-detail {
  height: 55px;
  width: auto !important;
}

.detail-header {
  width: 20%;
}

.detail-cell {
  border: 1px solid black;
  font-weight: normal;
  font-size: 15px;
  font-weight: bolder !important;
  padding-left: 10px;
}

.spinner-animation {
  width: 137px;
  height: 130px;
  background-image: url("assets/gryf_zver.png");
  background-size: 137px;
  position: relative;
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes animation {
  0% {
    background-color: red;
    left: 0;
    top: 0;
  }
  25% {
    background-color: yellow;
    left: 200px;
    top: 0;
  }
  50% {
    background-color: blue;
    left: 200px;
    top: 200px;
  }
  75% {
    background-color: green;
    left: 0;
    top: 200px;
  }
  100% {
    background-color: red;
    left: 0;
    top: 0;
  }
}

gryf-spinner {
  height: 0;
}

.spinner {
  .mat-mdc-dialog-container {
    box-shadow: none;
    background-color: transparent;
    .mdc-dialog__surface {
      box-shadow: none;
      background-color: transparent;
    }
  }
}

.tab-div, .e-offer-tab {
  height: 48px;
  width: 93.44px;
}

.tab-div b {
  position: relative;
  bottom: -16px;
  left: 23px;
}

.e-offer-tab b {
  position: relative;
  left: 12px !important;
  bottom: -12px !important;
}

ngb-tabset ul li a {
  padding: 0 !important;
}

.galery-selectedPhoto {
  position: absolute !important;
  bottom: 63.5%;
  left: 38%;
  z-index: 10;
}

.si-content::-webkit-scrollbar {
  width: 6px;
}

.si-content::-webkit-scrollbar-track {
  background: none;
}

.si-content::-webkit-scrollbar-thumb {
  background: none;
}

.si-content::-webkit-scrollbar-thumb:hover {
  background: none;
}

.surface-button, .manual-surface-button, .surface-button-still {
  left: calc(100vw - 65px);
  top: 40vh;
  width: 280px;
  z-index: 10;
  -webkit-transition: left 1s; /* Safari */
  transition: left 1s;
}

.surface-button-first {
  top: calc(40vh - 100px);
}

.surface-button-second {
  top: calc(40vh + 110px);
}

.surface-button-third {
  top: calc(40vh + 320px);
}

.surface-button-text, .surface-button-text-cookies {
  position: relative;
  transform: rotate(-90deg);
  transform-origin: left top 0;
}

.surface-button-text {
  top: 90px;
  right: 8px;
}

.surface-button-text-cookies {
  top: 44px;
  right: 5px;
}

.surface-button:hover, .manual-surface-button:hover {
  left: calc(100vw - 280px);
  cursor: pointer;
}

.surface-button-still:hover {
  left: calc(100vw - 65px) !important;
}

#surfaceButtonDiv {
  background-image: url("assets/gryf-bg-map.png");
  background-size: cover;
}

#promoButtonDiv {
  background-image: url("assets/photos/DSC_0189.jpg");
  background-size: cover;
}

#promoButtonDiv2 {
  //background-image: url("assets/photos/cookies.png");
  background-size: cover;
  background-color: #203a7f;
}

.manual-surface-button {
  background-image: url("assets/manual/7.jpg");
}

.section-color {
  background: #CBD6FA;
}

.navbar-toggler-icon--dark {
  background-image: url("assets/darkIcon.png") !important;
  background-size: 133% 121% !important;
}

.text-blue {
  color: #203a7f;
}

.border-blue {
  border: 3px solid #203a7f !important;
  margin-top: 3px !important;
}

gryf-manual-surface-offer {
  .background-manual:nth-child(2n) {
    background-color: #fff !important;
  }

  .background-manual:nth-child(2n + 1) {
    background-color: #f8f9fa !important;
  }

  .background-manual:nth-child(2n) .manual-display {
    display: none !important;
  }
}

.order--last {
  order: 1;
}

.order--first {
  order: 0;
}

@media (max-width: 992px) {
  .order--last {
    order: 0;
  }

  .order--first {
    order: 1;
  }
}

.gallery-modal {
  mat-dialog-container {
    padding: 0;
  }
}

div.blog-main-photo {
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding-top: 56.25%;
  position: relative;
}

div.blog-main-photo img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}

.blog-card {
  border-radius: 5px;
}

.card-transform-effect {
  transition: all .5s ease;
}

.card-transform-effect:hover {
  transform: translate3D(0, -1px, 0) scale(1.02);
  box-shadow: 8px 28px 50px rgba(39, 44, 49, .07), 1px 6px 12px rgba(39, 44, 49, .04);
  transition: all .4s ease;
  cursor: pointer;
}

.catalog_img {
  width: 50%;
}

.pagination-div {
  display: flex;
  justify-content: center;
}

.pagination-item {
  color: black;
  text-decoration: none;
  border-radius: 50%;
  cursor: pointer;
  float: left;
  list-style: none;
}

ul.pagination-list li a {
  color: black;
  padding: 16px 16px;
  text-decoration: none;
  border-radius: 50%;
  cursor: pointer;
}

ul.pagination-list li a:hover:not(.active) {
  background-color: #ddd;
}

.active-page {
  background-color: #203a7f;
  color: #fff !important;
}

.text-underline {
  text-decoration: underline !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.margin-0 {
  margin: 0 !important;
}

.darkred-color {
  color: darkred;
}

.width-100 {
  width: 100%;
}

.list-style-none {
  list-style-type: none;
}

.margin-right-24 {
  margin-right: 24px !important;
}

.mat-form-field + .mat-form-field {
  margin-left: 8px;
}

.display-block {
  display: block;
}

.display-inline-block {
  display: inline-block;
}

.pl-200 {
  padding-left: 200px;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer:hover {
  text-decoration: underline;
}

.text-decoration-none, text-decoration-none:hover {
  text-decoration: none !important;
}

.selected-cell {
  background-color: rgb(32, 58, 127);
  color: white;
}

.background-white {
  background: white;
}

.font-size-10-px {
  font-size: 10px
}

.font-size-13-px {
  font-size: 13px
}

.overflow-hidden {
  overflow: hidden;
}

.display-flex {
  display: flex;
}

.filter-blur-100 {
  filter: blur(100%);
}

.offer-table-arrow {
  font-size: 30px;
  position: absolute;
  top: -8px;
}

.div-scroll {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  height: 368px;
}

.not-found-text {
  text-align: center;
  font-weight: 900;
  font-size: 28px;
  color: #7c7878;
}

.padding-1rem {
  padding: 1rem !important;
}

.modal-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
  min-height: calc(100vh - 60px);
}
@media (max-width: 767px) {
  .modal-dialog {
    min-height: calc(100vh - 20px);
  }
}

.e-offer-heading-text {
  font-size: 13px;
  color: #212529 !important;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.75;
  transition: all 0.2s ease-in-out;
  transition-property: color;
  font-family: "Objective", sans-serif;
}

.margin-0-auto {
  margin: 0 auto !important;
}

.vertical-middle {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.width-50-perc {
  width: 50%;
}

.bold {
  font-weight: bold;
}

#detailImage {
  img {
    max-width: 100%;
    height: auto;
  }
}
