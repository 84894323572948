.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: $nav-link-padding-y $nav-link-padding-x;

  @include hover-focus {
    text-decoration: none;
  }

  &.disabled {
    color: $nav-link-disabled-color;
  }
}
