.bg {
  &-triangle {
    position: absolute;
    left: 0; right: 0; top: 0; bottom: 0;
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: 100% auto;

    &-left {

      background-image:
        str-replace(url("data:image/svg+xml;utf8,<svg xmlns='https://www.w3.org/2000/svg' width='100' height='100'><path d='M0 100 V 90 L 10 100 Z' fill='#{$bg-triangle-dark-bg}' fill-opacity='#{$bg-triangle-dark-opacity * 3}'></path><path d='M0 90 V 80 L 10 90 Z' fill='#{$bg-triangle-dark-bg}' fill-opacity='#{$bg-triangle-dark-opacity * 4.5}'></path><path d='M10 100 V 90 L 20 100 Z' fill='#{$bg-triangle-dark-bg}' fill-opacity='#{$bg-triangle-dark-opacity * 2.5}'></path><path d='M0 90 H 10 V 100 Z' fill='#{$bg-triangle-dark-bg}' fill-opacity='#{$bg-triangle-dark-opacity * 6}'></path></svg>"), "#", "%23");

      &.bg-triangle-light {

        background-image:
          str-replace(url("data:image/svg+xml;utf8,<svg xmlns='https://www.w3.org/2000/svg' width='100' height='100'><path d='M0 100 V 90 L 10 100 Z' fill='#{$bg-triangle-light-bg}' fill-opacity='#{$bg-triangle-light-opacity * 3}'></path><path d='M0 90 V 80 L 10 90 Z' fill='#{$bg-triangle-light-bg}' fill-opacity='#{$bg-triangle-light-opacity * 4.5}'></path><path d='M10 100 V 90 L 20 100 Z' fill='#{$bg-triangle-light-bg}' fill-opacity='#{$bg-triangle-light-opacity * 2.5}'></path><path d='M0 90 H 10 V 100 Z' fill='#{$bg-triangle-light-bg}' fill-opacity='#{$bg-triangle-light-opacity * 6}'></path></svg>"), "#", "%23");
      }
    }

    &-right {

      background-image:
        str-replace(url("data:image/svg+xml;utf8,<svg xmlns='https://www.w3.org/2000/svg' width='100' height='100'><path d='M100 100 V 90 L 90 100 Z' fill='#{$bg-triangle-dark-bg}' fill-opacity='#{$bg-triangle-dark-opacity * 4.5}'></path><path d='M100 90 V 80 L 90 90 Z' fill='#{$bg-triangle-dark-bg}' fill-opacity='#{$bg-triangle-dark-opacity * 1.5}'></path><path d='M90 100 V 90 L 80 100 Z' fill='#{$bg-triangle-dark-bg}' fill-opacity='#{$bg-triangle-dark-opacity * 3}'></path><path d='M90 100 V 90 H 100 Z' fill='#{$bg-triangle-dark-bg}' fill-opacity='#{$bg-triangle-dark-opacity * 6}'></path></svg>"), "#", "%23");

      &.bg-triangle-light {

        background-image:
          str-replace(url("data:image/svg+xml;utf8,<svg xmlns='https://www.w3.org/2000/svg' width='100' height='100'><path d='M100 100 V 90 L 90 100 Z' fill='#{$bg-triangle-light-bg}' fill-opacity='#{$bg-triangle-light-opacity * 4.5}'></path><path d='M100 90 V 80 L 90 90 Z' fill='#{$bg-triangle-light-bg}' fill-opacity='#{$bg-triangle-light-opacity * 1.5}'></path><path d='M90 100 V 90 L 80 100 Z' fill='#{$bg-triangle-light-bg}' fill-opacity='#{$bg-triangle-light-opacity * 3}'></path><path d='M90 100 V 90 H 100 Z' fill='#{$bg-triangle-light-bg}' fill-opacity='#{$bg-triangle-light-opacity * 6}'></path></svg>"), "#", "%23");
      }
    }

    &-top {
      transform: scaleY(-1);
    }
  }
}

.blog-title {
  a {
    color: #212529;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
}
