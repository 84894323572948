.gryf-checkbox-wrapper {
}

.gryf-checkbox {
  display: none;
}

.gryf-checkbox--disabled + .gryf-checkbox__text  {
  pointer-events: none;
  color: #DDDDDD;

  &:before {
    border: 1px solid #DDDDDD !important;
  }

  &:after {
    color: #DDDDDD !important;
  }
}

input.gryf-checkbox[type="checkbox"]:checked + .gryf-checkbox__text {
  &:before {
    border: 1px solid #203a7f;
  }
  &:after {
    font-size: 16px;
    content: "\2714";
    color: #203a7f;
    display: inline-block;
    position: absolute;
    left: 2px;
    top: -5px;
    line-height: 15px;
  }
}

.gryf-checkbox__text {
  cursor: pointer;
  color: #000000;
  position: relative;

  &:before {
    width: 15px;
    height: 15px;
    border: 1px solid #999999;
    background: white;
    border-radius: 2px;
    content: " ";
    margin-right: 5px;
    display: inline-block;
  }
}

.checkbox-body {
  display: flex;
  justify-content: flex-start;
}

.checkbox-body label {
  margin-top: 2px;
}
