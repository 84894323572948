.icon {

	&-2x {
		font-size: 2em !important;
	}

	path {
		fill: none;
		stroke: currentColor;
		stroke-width: 6;
		stroke-linecap: round;
		stroke-linejoin: round;
	}

	&-offset {

		path {

			@media all and (-ms-high-contrast:none) {
				stroke-dashoffset: 0 !important;
				animation: none !important;
			}

			@supports (-ms-ime-align:auto) {
				stroke-dashoffset: 0 !important;
				animation: none !important;
			}
		}
	}

	&-person {

		path {
			stroke-dasharray: $icon-person-path-length;
		}

		&.icon-offset {

			path {
				stroke-dashoffset: $icon-person-path-length;
			}
		}
	}

	&-envelope {

		path {
			stroke-dasharray: $icon-envelope-path-length;
		}

		&.icon-offset {

			path {
				stroke-dashoffset: $icon-envelope-path-length;
			}
		}
	}
}
