@mixin make-container() {
  width: 100%;
  padding-right: calc($grid-gutter-width / 2);
  padding-left: calc($grid-gutter-width / 2);
  margin-right: auto;
  margin-left: auto;
}

@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}

@mixin make-row() {
  display: flex;
  flex-wrap: wrap;
  margin-right: calc($grid-gutter-width / -2);
  margin-left: calc($grid-gutter-width / -2);
}

@mixin make-col-ready() {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: calc($grid-gutter-width / 2);
  padding-left: calc($grid-gutter-width / 2);
}

@mixin make-col($size, $columns: $grid-columns) {
  flex: 0 0 percentage(calc($size / $columns));
  max-width: percentage(calc($size / $columns));
}

@mixin make-col-offset($size, $columns: $grid-columns) {
  $num: calc($size / $columns);
  margin-left: if($num == 0, 0, percentage($num));
}
