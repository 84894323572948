.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

@each $color, $value in $theme-colors {
  @include list-group-item-variant($color, theme-color-level($color, -9), theme-color-level($color, 6));
}
