.section {
	position: relative;
	padding: $section-padding-y-sm 0;

	@include media-breakpoint-up(md) {
		padding: $section-padding-y 0;
	}

	.container {
		position: relative;
		z-index: 1;
    max-width: 1350px;
	}

	&-top {
		padding-top: $section-padding-y-sm + $navbar-height;

		@include media-breakpoint-up(md) {
			padding-top: $section-padding-y + $navbar-height;
		}
	}

	&-full {

		@include media-breakpoint-up(md) {

			.container {

				.row {
					min-height: calc(100vh - #{$section-padding-y} * 2);

					&:before {
						content: '';
						min-height: inherit;
					}
				}
			}

			&.section-top {

				> .container {

					> .row {
						min-height: calc(100vh - #{$section-padding-y} * 2 - #{$navbar-height});
					}
				}
			}
		}
	}
}
