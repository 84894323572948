.input {
	&-group {
		&-text {
			transition: border-color .15s;

			@at-root .form-light #{&} {
				background-color: fade-out(white, .95);
				border-color: fade-out(white, .75);
				color: fade-out(white, .5);
			}
		}

		&-icon {
			height: 1em; width: 1em;

			> path {
				stroke-width: 10 !important;
			}
		}

		&-append {

			&.order-0 {

				> .input-group-text {
					border-top-left-radius: $input-border-radius;
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
					border-bottom-left-radius: $input-border-radius;
					border-right: 0;
				}
			}
		}
	}
}

.form {

	&-control {

		@at-root .form-light #{&} {
			background-color: fade-out(white, .95);
			border-color: fade-out(white, .75);
			color: white;

			&::placeholder {
				color: fade-out(white, .5);
			}
		}

		&.order-1 {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			border-top-right-radius: $input-border-radius !important;
			border-bottom-right-radius: $input-border-radius !important;
			border-left: 0;
		}

		&:not(:focus) {

			+ .input-group-append {

				.input-group-icon {

					path {
						stroke-dashoffset: 0;
					}
				}
			}
		}

		&:focus {
			background-color: white;

			@at-root .form-light #{&} {
				background-color: transparent;
				border-color: $primary;
			}

			+ .input-group-append {

				.input-group-text {
					background-color: white;
					border-color: $input-focus-border-color;
					color: $primary;

					@at-root .form-light #{&} {
						background-color: transparent;
					}
				}

				.input-group-icon {

					> path {
						animation: icon .5s linear forwards;

						@keyframes icon {
							to {
								stroke-dashoffset: 0;
							}
						}
					}
				}
			}
		}
	}
}

label {
	font-size: $font-size-xs;
	font-weight: $font-weight-bold;
	text-transform: uppercase;
	color: $text-muted;

	@at-root .form-light #{&} {
		color: fade-out(white, .5);
	}
}
